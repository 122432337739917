import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';


import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';



import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const fiveDaysBefore = new Date(currentDate.getTime() - 4 * 24 * 60 * 60 * 1000);
  const oneDayAfter = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  const formatDateString = (date) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };





useEffect(() => {
  const fetchLocationData = () => {
    fetch('/api/location')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setCountry(decodeURIComponent(data.country));
        setCity(decodeURIComponent(data.city));
        
        // Remove numbers from city and country names
        const cleanCity = data.city.replace(/[0-9]/g, '');
        const cleanCountry = data.country.replace(/[0-9]/g, '');

        const address = `${encodeURIComponent(cleanCity)}, ${encodeURIComponent(cleanCountry)}`;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBTT1Epe-qKRGcLSPos2waBZ-2M5dkMfew`;
        console.log("URL Requested: ", url); // Log the URL
        return axios.get(url);
      })
      .then(response => {
        console.log("Google Geocoding API Response: ", response.data); // Log the full response
        const results = response.data.results;
        if (results.length > 0) {
          const addressComponents = results[0].address_components;
          const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
          if (stateObj) {
            setRegion(stateObj.long_name);
          }
        }
        setFetchAttempted(true);
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setCountry('Unavailable');
        setRegion('');
        setCity('');
        setFetchAttempted(true);
      });
  };

  if (!region && !fetchAttempted) {
    fetchLocationData();
  }
}, [region]); // Dependency on region

  useEffect(() => {
    // Update the countdown timer every second
    const timer = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    // Clear the timer when component unmounts or timeLeft changes
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the remaining time as "0h 0m 0s"
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };


  return (
    <div className="container">


    <a class="header-logo" href="https://t.me/mattmannofm">
     <div class="onlyfans"></div>
    </a>
      <img 
        src="https://i.ibb.co/VLNt42R/photo-2024-03-20-20-27-06-modified.png" 
        alt="Header Image"
        className="header-image"
      />

<div className='textContainer'>
        <section>
            <div className='username'><strong>Matthew Mann</strong></div>
            <div className='useraccountname'>@immattmann</div>

        </section>
      </div>



      <div className="textContainer3">
        <a href="https://t.me/+ElOKylQSMg02ODdh" id="customButton">
          <img 
            src="https://i.ibb.co/RPY4ZKB/95d8ba63-4446-45f1-bd8e-75948bbe5507-ZK1-Wkc-Y-Aun-KUOo6-GCPn-Pid1-Pvi-Ebo0-ZTR5rz7-GB3tfv6e9-Rng-Q7.webp" 
            alt="Decorative"
            style={{ height: '40px', width: 'auto' }}
          />
          <span>WiFi Money Secrets 🧙‍♂️📈</span>
        </a>
      </div>


      <div className="textContainer3">
        <a href="https://whop.com/magicinterntmoney/" id="customButton">
          <img 
            src="https://i.ibb.co/HTR86hP/download-4.png" 
            alt="Decorative"
            style={{ height: '40px', width: 'auto' }}
          />
          <span>Wi-Fi Money Secrets Courses</span>
        </a>
      </div>



      <div className="textContainer3">
        <a href="https://t.me/mattmannofm" id="customButton">
          <img 
            src="https://i.ibb.co/6msYz7c/Telegram-2019-Logo-svg.png" 
            alt="Decorative"
            style={{ height: '40px', width: 'auto' }}
          />
          <span>Matt Mann (Telegram)</span>
        </a>
      </div>

      <div className="textContainer3">
        <a href="https://instagram.com/immattmann?igshid=MzRlODBiNWFlZA==" id="customButton">
          <img 
            src="https://i.ibb.co/kxLK7Y0/Instagram-logo-2016-svg.png" 
            alt="Decorative"
            style={{ height: '40px', width: 'auto' }}
          />
          <span>Instagram</span>
        </a>
      </div>

      <div className="textContainer3">
        <a href="https://twitter.com/urhosfavorite?s=21&t=x4aE-GU2xAK9TIv3Ki0auA" id="customButton">
          <img 
            src="https://i.ibb.co/NWZk4JP/new-2023-twitter-logo-x-icon-design-1017-45418-modified.png" 
            alt="Decorative"
            style={{ height: '40px', width: 'auto' }}
          />
          <span>Twitter</span>
        </a>
      </div>

      <div className="textContainer3">
        <a href="https://youtube.com/@immattmann?si=wSrjx-IvqMACVIyj" id="customButton">
          <img 
            src="https://i.ibb.co/whJcR1S/cropped-You-Tube-social-white-circle-2017-svg.png" 
            alt="Decorative"
            style={{ height: '40px', width: 'auto' }}
          />
          <span>Youtube</span>
        </a>
      </div>




    
      <Analytics/>
    </div>





  );
}

export default App;
